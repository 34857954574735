<template>
  <a-modal
    :visible="visible"
    :footer="null"
    :header="null"
    @cancel="closeModal"
    wrapClassName="report-modal"
  >
    <div class="report">
      <h2 class="report__title">Report</h2>
      <h4 class="report__sub-title">
        {{ title }}
      </h4>
      <p class="report__dec">
        {{ reportDescription  }}
      </p>
      <p v-if="unselectRadio" class="error">Please select any one of them.</p>
      <a-radio-group name="radioGroup" v-model:value="value">
        <a-radio value="advertisement">Advertisement</a-radio>
        <a-radio value="nudity" >Nudity</a-radio>
        <a-radio value="inciting voilence" >Inciting violence</a-radio>
        <a-radio value="terrorism" >Terrorism</a-radio>
        <a-radio value="spam" >Spam</a-radio>
        <a-radio value="something else" >Something else that is not a current event</a-radio>
        <a-radio value="other" >Other's</a-radio>
      </a-radio-group>
      <transition name="text-content" mode="out-in">
        <div v-if="value == 'other'">
          <h4 class="report__sub-title">Reason</h4>
          <p class="report__dec">Please write a reason here.</p>
          <textarea v-model="detail" rows="4" placeholder="Write a message" ></textarea>
          <p v-if="error" class="error">This field is required.</p>
        </div>
      </transition>
      <a-button :loading="loader" class="report__btn" @click="SubmitReport">Submit report</a-button>
    </div>
  </a-modal>
</template>

<script>
export default {
  emits: ["handle-close"],
  props: {
    visible: Boolean,
    title: String,
    reportDescription: String,
    storyId: String
  },
  data() {
    return {
      value: "",
      detail: "",
      message: {},
      showMessageModal: false,
      loader: false,
      error: false,
      unselectRadio: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("handle-close", false);
    },
    async SubmitReport() {
      if(this.value == ""){
        this.unselectRadio = true;
        return false;
      }
      if(this.value == "other" && this.detail == ""){
        this.unselectRadio = false;
        this.error = true;
        return false;
      }
      this.loader = true;
      try{
        const payload = {
          story_id: this.storyId,
          reason: this.value,
          description: this.detail
        }
        const response = await this.$store.dispatch('impact/impactArticleReport', payload);
        if(response.status == 201){
          this.loader = false;
          this.closeModal();
          this.detail = "";
          this.error = false;
          this.unselectRadio = false;
          this.showMessageModal = true;
          this.message = {
            title: "Report Submitted Successfully",
            // desc: 'Thanks for letting us know. We w\'ll use this information to improve our processes.',
            type: "success",
          };
        }
        // console.log(this.value, this.detail)
      }catch (err){
        console.log(err)
      }
    },
  },
};
</script>

<style lang="scss">
.report-modal {
  .ant-modal {
    width: 60rem !important;
    .ant-modal-content {
      padding: 3rem;
      .ant-modal-body {
        .report {
          &__title {
            font-size: 2.5rem;
            font-family: $font-primary-bold;
            margin-bottom: 2rem;
            line-height: normal;
          }
          &__sub-title {
            font-size: 2.2rem;
            font-family: $font-primary;
            margin-bottom: 0.7rem;
            line-height: normal;
            color: $color-black;
          }
          &__dec {
            font-size: 1.6rem;
            font-family: $font-primary;
            margin-bottom: 2rem;
            line-height: normal;
            color: $color-black;
            opacity: 0.7;
          }
          textarea {
            width: 100%;
            padding: 1rem;
            outline: none;
            border: 0.22rem solid #d5d5d5;
            resize: none;
            font-size: 1.6rem;
            font-family: $font-primary;
            line-height: 2.2rem;
            margin-bottom: 0.5rem;
            &::placeholder {
              opacity: 0.6;
            }
          }
          &__btn {
            width: 100%;
            background-color: $color-primary;
            border: none;
            height: 4rem;
            font-size: 1.6rem;
            font-family: $font-primary;
            color: $color-white;
            margin-top: 1rem;
            span {
              line-height: normal;
            }
          }
          .ant-radio-group {
            display: flex;
            flex-direction: column;
            margin-bottom: 2rem;
            .ant-radio-wrapper {
              font-size: 1.6rem;
              font-family: $font-primary;
              margin-bottom: 0.5rem;
              display: flex;
              align-items: center;
              .ant-radio-inner {
                border-color: gray;
                width: 2rem;
                height: 2rem;
              }
            }
            .ant-radio-wrapper-checked {
              .ant-radio {
                .ant-radio-inner {
                  border-color: $color-primary;
                  position: initial;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  &::after {
                    position: initial;
                    width: 0.9rem;
                    height: 0.9rem;
                    background-color: $color-primary;
                    @include respond(phone-large) {
                      width: 0.8rem;
                      height: 0.8rem;
                    }
                  }
                }
              }
            }
          }
          .error {
            color: red;
            font-size: 1.6rem;
            font-family: $font-primary;
            margin-top: 0;
            margin-bottom: 2rem;
          }
        }
      }
    }
    .text-content-enter-from,
    .text-content-leave-to {
      opacity: 0;
    }
    .text-content-enter-active {
      transition: opacity 0.5s ease-in-out;
    }
    .text-content-enter-to,
    .text-content-leave-from {
      opacity: 1;
    }
  }
}
</style>
